import React, { Component } from "react";
import Layout from "../components/layout";
import { FormattedMessage } from "react-intl";
import { graphql } from "gatsby";
import { I18Link } from "../components/i18link";
import { FormattedDate } from "../components/date";

class ReleasesPage extends Component {
  render() {
    const {
      pageContext: { language, title, id, featuredImage },
      data,
    } = this.props;

    return (
      <Layout
        locale={language}
        title={title}
        pageId={id}
        featuredImage={featuredImage}
      >
        <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div>
              <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                What's new?
              </h1>
              <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                <p className="text-xl text-gray-500">
                  New releases, UX, Announcements
                </p>
              </div>
            </div>
            <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
              {data.entries.edges.map((edge, index) => (
                <div key={`release_${index}`}>
                  <p className="text-sm text-gray-500">
                    <time dateTime="2020-03-16">
                      <FormattedDate
                        locale={language}
                        value={edge.node.frontmatter.date}
                      />
                    </time>
                  </p>
                  <I18Link className="mt-2 block" id={edge.node.fields.id}>
                    <h2 className="text-xl font-semibold text-gray-900">
                      {edge.node.frontmatter.title}
                    </h2>
                    <p className="mt-3 text-base text-gray-500">
                      {edge.node.excerpt}
                    </p>
                  </I18Link>
                  <div className="mt-3">
                    <I18Link
                      className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
                      id={edge.node.fields.id}
                    >
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <FormattedMessage id="blog.read-article" />
                        <svg
                          className="ml-2 w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 5l7 7-7 7"
                          ></path>
                        </svg>
                      </button>
                    </I18Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query ReleasesPageQuery {
    entries: allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "release" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            tags
            date
            version
          }
          excerpt
          fields {
            id
          }
        }
      }
    }
  }
`;

export default ReleasesPage;
